import './App.scss';
import Result from './components/Result'
import React, {useEffect, useState} from 'react';
import Loader from "./components/Loader"
import OldTestDisplayer from "./components/OldTestDisplayer";
import OlderResults from "./components/OlderResults";
import VideoPlayer from "./components/VideoPlayer";

/* global tf_analyseNonce */

function App() {
    const [testState, setTestState] = useState(false)
    const [testAvail, setTestAvail] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [loading, setLoading] = useState(false)
    const [oldAnalysis, setOldAnalysis] = useState([])
    const [resultsAnalyse, setResultsAnalyse] = useState('');
    const [hasNewAnalyse, setHasNewAnalyse] = useState(false);
    const [percentAnalyse, setPercentAnalyse] = useState(0);
    const [percentAddiction, setPercentAddiction] = useState(0);
    const [percentMotivation, setPercentMotivation] = useState(0);
    const [resultsToDisplay, setResultsToDisplay] = useState(null);
    const [video, setVideo] = useState(true);
    const [videoNoTest, setVideoNoTest] = useState(false);
    const [maxReached, setMaxReached] = useState(false);
const [nextDate,setNextDate]=useState(null)
    const [analyseLeft,setAnalyseLeft]=useState(0);
    const [userLang, setUserLang] = useState(navigator.language);
    const translations = {
        en: {
            noTest: 'You must first complete the addiction and motivation tests.',
            noAddiction: 'You must first take the addiction test.',
            noMotivation: 'You must first perform the motivation test',
            toOldAddiction: 'Your addiction test is too old, please take it again.',
            toOldMotivation: 'Your motivation test is too old, please take it again.',
            LoaderFirstAnalyse: 'We analyze the results of your tests...',
            LoaderNewAnalyse: 'we take into account your latest tests for a new analysis',
            urlVideo: 'https://m67.tech/wp-content/uploads/2024/04/Analyse-EN.mp4',
            urlVideoNotTest: 'https://m67.tech/wp-content/uploads/2024/04/videoNotTest_ES.mp4',//erreur dans le nommage des fichier
            urlVideoMaxReached: 'https://m67.tech/wp-content/uploads/2024/04/MaxAnalyseReached_FR.mp4',//todo a remplacer par la bonne version de langue

        },
        fr: {
            noTest: "Vous devez d'abord effectuer les tests d'addiction et de motivation.",
            noAddiction: 'Vous devez d\'abord effectuer le test d\'addiction.',
            noMotivation: 'Vous devez d\'abord effectuer le test de motivation.',
            toOldAddiction: "Votre test d'addiction est trop ancien, merci de le faire à nouveau.",
            toOldMotivation: 'Votre test de motivation est trop ancien, merci de le faire à nouveau.',
            LoaderFirstAnalyse: 'Nous analysons les resultats de vos tests...',
            LoaderNewAnalyse: 'nous prenons en compte vos derniers tests pour une nouvelle analyse',
            urlVideo: 'https://m67.tech/wp-content/uploads/2024/04/Analyse-FR.mp4',
            urlVideoNotTest: 'https://m67.tech/wp-content/uploads/2024/04/videoNotTest_FR.mp4',
            urlVideoMaxReached: 'https://m67.tech/wp-content/uploads/2024/04/MaxAnalyseReached_FR.mp4',
        },
        es: {
            noTest: 'Primero debes completar las pruebas de adicción y motivación.',
            noAddiction: 'Primero debes realizar el test de adicción.',
            noMotivation: 'Primero debes realizar el test de motivación.',
            toOldAddiction: "Tu prueba de adicción es demasiado antigua, vuelva a realizarla.",
            toOldMotivation: "Tu prueba de motivación es demasiado antigua, vuelva a realizarla.",
            LoaderFirstAnalyse: 'Analizamos los resultados de tus pruebas...',
            LoaderNewAnalyse: 'tomamos en cuenta tus últimas pruebas para un nuevo análisis',
            urlVideo: 'https://m67.tech/wp-content/uploads/2024/04/Analyse-ES.mp4',
            urlVideoNotTest: 'https://m67.tech/wp-content/uploads/2024/04/videoNotTest_EN.mp4',//erreur dans le nommage des fichier
            urlVideoMaxReached: 'https://m67.tech/wp-content/uploads/2024/04/MaxAnalyseReached_FR.mp4',//todo a remplacer par la bonne version de langue
        }
    };
    useEffect(() => {
        setLoading(true)
        const fetchTestStatus = async () => {
            const url = 'https://m67.tech/wp-json/tabac_finisher/v1/testStatus'; // Remplacez par l'URL correcte de votre API
            try {
                const response = await fetch(url, {
                    method: 'GET', // ou 'POST' selon la configuration de votre route
                    headers: {
                        'Content-Type': 'application/json',

                        'X-WP-Nonce': tf_analyseNonce.nonce,
                    },
                    credentials: 'include' // Nécessaire pour les cookies d'authentification, si l'API est utilisée sur le même domaine
                });

                if (!response.ok) {
                    throw new Error('Réponse réseau non ok.');
                }

                const data = await response.json();
                //console.log(data['state']);
                // console.log('data[\'isPremium\']',data['isPremium'])
                // console.log(' data[\'analyseCount\'] ', data['analyseCount'] )
                // Mettre à jour l'état avec les données reçues
                setTestState(data['state']);
                setAnalyseLeft(data['analyseLeft']);
                if (data['state'] === 'okTest') {

                    if (data['nextAnalyseIn'] <= 0 || data['nextAnalyseIn'] === null) {
                        if (data['isPremium'] || data['analyseLeft'] >0) {

                            setTestAvail(true);
                            setVideo(false);
                            getAnalyse('new');
                        } else if (!data['isPremium'] && data['analyseLeft'] <=0) {

                            setMaxReached(true);
                            setTestAvail(false);
                            setVideo(false);
                            setNextDate(data['nextAnalyseIn']);
                            getOldAnalyse();
                        }
                    }else{
                        setTestAvail(false);
                        setNextDate(data['nextAnalyseIn']);
                        setVideo(false);
                        getOldAnalyse();


                    }

                } else if (data['state'] === 'doAnalyse' && data['analyseLeft'] >0) {
                    //console.log('analyse')
                    setTestAvail(true);
                    setVideo(true);
                    getAnalyse('first');

                } else {
                    const userMessage = translations[userLang.slice(0, 2)] || translations["en"];
                    //alert(userMessage[data['state']]);
                    setNextDate(data['nextAnalyseIn']);
                    setVideoNoTest(true)
                    setTestAvail(false);
                    setVideo(false);
                    getOldAnalyse();

                }

            } catch (error) {
                console.error('Erreur lors de la récupération du statut du test:', error);
            }
        };

        fetchTestStatus();
    }, []);
    useEffect(() => {
        if (!hasNewAnalyse && testAvail) {
            setTestAvail(false);
            setVideo(false);
            getOldAnalyse();

        }
    }, [hasNewAnalyse,nextDate]);
    const getOldAnalyse = () => {
        //to recuperer les anciennes analyse tf_analyse/v1', '/oldAnalyse/
        const getOlderAnalyse = async () => {
            setLoading(true);
            const url = 'https://m67.tech/wp-json/tf_analyse/v1/oldAnalyse/'; // Remplacez par l'URL correcte de votre API
            try {
                const response = await fetch(url, {
                    method: 'GET', // ou 'POST' selon la configuration de votre route
                    headers: {
                        'Content-Type': 'application/json',

                        'X-WP-Nonce': tf_analyseNonce.nonce,
                    },
                    credentials: 'include' // Nécessaire pour les cookies d'authentification, si l'API est utilisée sur le même domaine
                });

                if (!response.ok) {
                    throw new Error('Réponse réseau non ok.');
                }
                const data = await response.json();
                console.log(data);
                setOldAnalysis(data)
                setVideoNoTest(false)
                setLoading(false)

            } catch (error) {
                setLoading(false);
                console.error('Erreur lors de la récupération du statut du test:', error);
            }
        }

        getOlderAnalyse();

    }


    const getAnalyse = (firstOrNew) => {

            setLoading(true);
            if (firstOrNew === 'new') {
                setLoadingText((translations[userLang.slice(0, 2)] || translations["en"]).LoaderNewAnalyse);
            } else {
                setLoadingText((translations[userLang.slice(0, 2)] || translations["en"]).LoaderFirstAnalyse);
            }

            const getAnalyseRequest = () => {
                const url = 'https://m67.tech/wp-json/tf_analyse/v1/getAnalyse'; // Remplacez par l'URL correcte de votre API

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-WP-Nonce': tf_analyseNonce.nonce,
                    },
                    credentials: 'include' // Nécessaire pour les cookies d'authentification, si l'API est utilisée sur le même domaine
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        setResultsAnalyse(data.analyse);
                        setHasNewAnalyse(true);
                        setPercentAnalyse(extractAndRemovePercent(data.analyse));
                        setPercentAddiction(data.percentAddiction);
                        setPercentMotivation(data.percentMotivation);
                        setAnalyseLeft(data.creditLeft)
                        setLoading(false);
                        setNextDate(15)
                    })
                    .catch(error => {
                        console.error('Erreur lors de la récupération du statut du test:', error);
                    });
            }


            getAnalyseRequest();


    }

    function extractAndRemovePercent(text) {
        // Ajout du drapeau global 'g' pour trouver toutes les occurrences
        const regex = /\[([^\]]+)%\]/g;
        const matches = [...text.matchAll(regex)];
        const lastMatch = matches[matches.length - 1];

        if (lastMatch && lastMatch[1]) {
            // Suppression du caractère "%" et retour du contenu nettoyé de la dernière correspondance
            console.log(lastMatch);
            const cleaned = lastMatch[1].replace(/%/g, '');
            return cleaned;
        }

        return null; // Retourne null si aucune correspondance n'est trouvée
    }

//voir si l'utilisateur a fait les 2 tests
//envoi d'une requete au serveur sur route de generation de l'analyse
//SERVER => verifier si nouveau plan depuis ancienne generation
    return (
        <div className="App">
            {loading && <Loader loadtext={loadingText}/>}
            {videoNoTest && !loading && !resultsToDisplay && <VideoPlayer className='player'
                                                                          src={(translations[userLang.slice(0, 2)] || translations["en"]).urlVideoNotTest}/>}
            {video && !loading && !maxReached && <VideoPlayer className='player'
                                                              src={(translations[userLang.slice(0, 2)] || translations["en"]).urlVideo}/>}
            {maxReached && !loading && <VideoPlayer className='player'
                                                    src={(translations[userLang.slice(0, 2)] || translations["en"]).urlVideoMaxReached}/>}
            {!videoNoTest && resultsToDisplay !== null &&
                <OlderResults text={resultsToDisplay} setResultsToDisplay={setResultsToDisplay}/>}
            {hasNewAnalyse &&
                <Result  setHasNewAnalyse={setHasNewAnalyse} textResult={resultsAnalyse} addictPercent={percentAddiction}
                        motivPercent={percentMotivation} analysePercent={percentAnalyse}/>}

            {!testAvail && <OldTestDisplayer analyseLeft={analyseLeft} nextDate={nextDate} setResultsToDisplay={setResultsToDisplay} oldAnalyse={oldAnalysis}
                                             testAutorisation={true}/>}


        </div>
    );
}

export default App;
