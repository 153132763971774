import React, { useEffect, useState } from 'react';
import '../css/AnalysePackCredit.scss';
import OldTestThumbail from "./OldTestThumbail";
import Chartanalyse from './ChartAnalyse'
/* global tf_analyseNonce */
/* global tabac_finisherNonce */
function AnalysePackCredit({userID}) {
    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {

            analyseReload5:  'Reload',
            analysReloadCount5: '5 CREDITS + 1 FREE',
            analysReloadPrice5 :'4.5 euro',
            creditAnalyse: 'Remaining credits:',
            unautorizedTest:'Remaining time:',
            delaisAdvice :'Minimum 15-day delay between 2 analyses.',
            days:'day(s)'
        },
        fr: {

            analyseReload5:  'Rechargez',
            analysReloadCount5: '5 CREDITS + 1 OFFERT',
            analysReloadPrice5 :'4.5 euro',
            creditAnalyse: 'Crédit(s) restants:',
            unautorizedTest:'Temps restant:',
            delaisAdvice :'Délais de 15 jours minimum entre 2 analyses.',
            days:'jour(s)'
        },
        es: {

            analyseReload5:  'Recargar',
            analysReloadCount5: '5 CREDITOS + 1 OFERTA',
            analysReloadPrice5 :'4.5 euro',
            creditAnalyse:'Créditos restantes  ',
            unautorizedTest:'Tiempo restante:',
            delaisAdvice :'Retraso mínimo de 15 días entre 2 análisis.',
            days:'día(s)'
        }
    };

const getStripe = async () => {

        const url = 'https://m67.tech/wp-json/tabac_finisher/v1/checkoutsessionn/'; // Remplacez par l'URL correcte de votre API
        try {
            const response = await fetch(url, {
                method: 'POST', // ou 'POST' selon la configuration de votre route
                headers: {
                    'Content-Type': 'application/json',

                    'X-WP-Nonce': tabac_finisherNonce.nonce,
                },
                credentials: 'include' // Nécessaire pour les cookies d'authentification, si l'API est utilisée sur le même domaine
            });

            if (!response.ok) {
                throw new Error('Réponse réseau non ok.');
            }
            const data = await response.json();
            console.log(data);


        } catch (error) {

            console.error('Erreur lors de la récupération du statut du test:', error);
        }
    }

    return (
        <div className='analyseCreditPack-container'>

            <div className='buyButton-container'>
                <form action="/wp-json/tabac_finisher/v1/checkoutsession5" className='analyseForm' method="POST">
                    <input type="hidden" name="user" value={userID}/>
                    <button type="submit" className='btn-recharge'>
                        <div className='analyseReloadContainer'>
                            <span className='analyseReload5'>
                            {(translations[userLang.slice(0, 2)] || translations["en"]).analyseReload5}
                            </span>
                            <span className='analysReloadCount5'>
                                {(translations[userLang.slice(0, 2)] || translations["en"]).analysReloadCount5}
                            </span>
                            <span className='analysReloadPrice5'>
                                {(translations[userLang.slice(0, 2)] || translations["en"]).analysReloadPrice5}
                            </span>
                        </div>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default AnalysePackCredit;
