import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
/* global tf_analyseNonce */
const ChartAnalyse = () => {
    const [dataSeries, setDataSeries] = useState({ addiction: [], motivation: [], reussite: [] });
    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            addiction: 'Dependency',

            motivation:'Motivation',
            reussite: 'Chance of success'

        },
        fr: {
            addiction: 'Dépendance',

            motivation:'Motivaition:',
            reussite:'Chance de réussite'

        },
        es: {
            addiction: 'Dependencia',

            motivation:'Motivación',
            reussite:'Posibilidad de éxito'

        }
    };
    useEffect(() => {
        const url = 'https://m67.tech/wp-json/tf_analyse/v1/getGraphAnalyseData/';
        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_analyseNonce.nonce,
            },
        })
            .then(response => response.json())
            .then(data => {
                const newDataSeries = { addiction: [], motivation: [], reussite: [] };
                data.forEach(item => {
                    // Convertir les dates en format ISO ou objet Date ici
                    const dateISO = item.analyseDate.split(" ")[0];
                    newDataSeries.addiction.push({ date: dateISO, value: parseInt(item.addictionPercent, 10) });
                    newDataSeries.motivation.push({ date: dateISO, value: item.motivationPercent ? parseInt(item.motivationPercent, 10) : 0 });
                    newDataSeries.reussite.push({ date: dateISO, value: parseInt(item.percent, 10) });
                });
                setDataSeries(newDataSeries); // Mettre à jour l'état avec les nouvelles données
            })
            .catch(error => console.error(error));
    }, []);

    // Préparer les données pour le graphique
    const chartData = {
        labels: dataSeries.addiction.map(point => point.date),
        datasets: Object.keys(dataSeries).map((serieKey, index) => {
            const color = ['#FF022E', '#3243FF', '#FFF31E'][index];
            return {
                label: ((translations[userLang.slice(0, 2)] || translations["en"])[serieKey]),
                data: dataSeries[serieKey].map(point => ({ x: point.date, y: point.value })),
                borderColor: color,
                backgroundColor: color,
            };
        }),
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'yyyy-MM-dd',
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default ChartAnalyse;
