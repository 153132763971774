import React, { useEffect, useState } from 'react';
import '../css/OldTestDisplayer.scss';
import OldTestThumbail from "./OldTestThumbail";
import Chartanalyse from './ChartAnalyse'
import AnalysePackCredit from "./AnalysePackCredit";
import AnalyseUnityCredit from "./AnalyseUnityCredit";
/* global tf_analyseNonce */

function OldTestDisplayer({analyseLeft,nextDate, oldAnalyse,setResultsToDisplay, testAutorisation}){

    const [userID, setUserID] =useState(0)
    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            oldTestContainerTitleText: <span>Your previous Analyse.</span>,
            creditAnalyse: 'Remaining credits:',
            unautorizedTest:'Remaining time:',
            delaisAdvice :'Minimum 15-day delay between 2 analyses.',
            days:'day(s)'
        },
        fr: {
            oldTestContainerTitleText:  <span>Vos analyses précédentes.</span>,
            creditAnalyse: 'Crédit(s) restants:',
            unautorizedTest:'Temps restant:',
            delaisAdvice :'Délais de 15 jours minimum entre 2 analyses.',
            days:'jour(s)'
        },
        es: {
            oldTestContainerTitleText: <span>Tus análisis anteriores.</span>,
                creditAnalyse:'Créditos restantes  ',
            unautorizedTest:'Tiempo restante:',
            delaisAdvice :'Retraso mínimo de 15 días entre 2 análisis.',
            days:'día(s)'
        }
    };
    const fetchCurrentUser = async () => {
        const url = '/wp-json/tabac_finisher/v1/current-user';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': tf_analyseNonce.nonce // Remplacez par votre nonce réel
                },
                credentials: 'include' // Nécessaire pour inclure les cookies de session
            });

            if (!response.ok) {
                throw new Error('Réponse réseau non ok.');
            }

            const data = await response.json();
            setUserID(data['ID']); // Logique pour traiter les données de l'utilisateur
        } catch (error) {
            console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
        }
    };

// Exécutez la fonction
    fetchCurrentUser();


    return <div className="result-component">
        <div className='result-tilte'>
            <hr/>
            {(translations[userLang.slice(0, 2)] || translations["en"]).oldTestContainerTitleText}
            <hr className='hrbottomMargin'/>

        </div>
        <Chartanalyse/>
        <div className='oldResult-container madimi-one-regular'>
            {oldAnalyse && oldAnalyse.map(test => {


                return <OldTestThumbail key={test.ID} setResultsToDisplay={setResultsToDisplay} percent={test.percent}
                                        analyseResults={test.analyse_Results} analyseDate={test.analyseDate}/>

            })
            }
            {nextDate && <div className='unautorizedTest'>


                <span
                    className='analysCreditTitle'>{(translations[userLang.slice(0, 2)] || translations["en"]).creditAnalyse} {analyseLeft}</span>
                <div className='reloadAnalyseCredit'>
                    <AnalyseUnityCredit user={userID}/>
                    <AnalysePackCredit user={userID}/>

                </div>
                <span>{(translations[userLang.slice(0, 2)] || translations["en"]).unautorizedTest}<span className='colorRed'> {nextDate} </span>{(translations[userLang.slice(0, 2)] || translations["en"]).days}</span>
                <span
                    className='delaisTestAdvice '>{(translations[userLang.slice(0, 2)] || translations["en"]).delaisAdvice}</span>
            </div>}
        </div>


    </div>
}

export default OldTestDisplayer;