import React, {useEffect, useState} from 'react';
import '../css/OldTestThumbail.scss';


function OldTestThumbail({key,setResultsToDisplay,analyseResults, percent, analyseDate}) {


    const formatDate = (datetime) => {
        const datePart = datetime.split(' ')[0];
        // Divise la date sur "-" pour obtenir un tableau [année, mois, jour]
        const parts = datePart.split('-');
        // Réarrange les parties pour obtenir le format "DD/MM/YYYY"
        return `${parts[2]}/${parts[1]}/${parts[0]}`
    };
    const handleSetResultsToDisplay=()=>
    {
        setResultsToDisplay(analyseResults);
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Pour un défilement doux
        });
    }
    return (
        <div id={'analysethumb-'+{key}} className='testThumb' onClick={handleSetResultsToDisplay}><span>{percent}%</span>

            <span className='result_thumbailDate'>{formatDate(analyseDate)}</span> </div>
    )
}

export default OldTestThumbail;
