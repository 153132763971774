import React, { useEffect, useState } from 'react';
import '../css/Result.scss';


function OlderResults({text, setResultsToDisplay}){

    const [userLang, setUserLang] = useState(navigator.language );
    const translations = {
        en: {
            resultTitle: <span>Analysis of your dependency.</span>,
            btnBack:'Back',
        },
        fr: {
            resultTitle:  <span>Analyse de votre dépendence.</span>,
            btnBack:'Retour',
        },
        es: {
            resultTitle: <span>Análisis de tu dependencia.</span>,
            btnBack:'Atrás',
        }
    };
    const getPercent =(data)=>{     const regex = /\[(.*?)\]\s*([\s\S]*)/;

        const match = data.match(regex);

        if (match) {
           return(match[1].replace('%', '')); // Contiendra 'valeur'


        }

    }
    // Fonction pour convertir les \n en éléments <br />

    function transformTextToReact(inputText) {
        // Découpage en segments basé sur les marqueurs ** et --
        const parts = inputText.split(/(\*\*[^*]+\*\*|--[^-]+--)/g);

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <div key={index} className='texteTitle'>{part.slice(2, -2)}</div>;
            } else if (part.startsWith('--') && part.endsWith('--')) {
                return <div key={index} className='texteSubTitle'>{part.slice(2, -2)}</div>;
            }
            return part.split('\n').map((item, subIndex) => (
                <React.Fragment key={`${index}-${subIndex}`}>
                    {item}{subIndex < item.length - 1 && <br />}
                </React.Fragment>
            ));
        });
    }

    return <div className="result-component">

        <div className='result-tilte'>
            <hr/>
            {(translations[userLang.slice(0, 2)] || translations["en"]).resultTitle}
            <hr/>

        </div>

        <div className='textResult-content madimi-one-regular'>
            {transformTextToReact(text)}
        </div>
        <button className="button button-primary btn-newTest"  onClick={() => {setResultsToDisplay(null)}} >{(translations[userLang.slice(0, 2)] || translations["en"]).btnBack}</button>
    </div>
}

export default OlderResults;